import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const MainText = styled.div`
  font-size: 20px;
`;

function App() {
  return (
    <Container>
      <MainText>Billionaire Boys Club</MainText>
    </Container>
  );
}

export default App;
